import { axios } from '@/utils/request'

export function getLiveChannel(parameter) {
    return axios({
        url: 'lives/channel',
        method: 'get',
        params: parameter
    })
}

export function getLive(parameter) {
    return axios({
        url: 'lives',
        method: 'get',
        params: parameter
    })
}
export function getLiveDetail(liveId) {
    return axios({
        url: `lives/${liveId}`,
        method: 'get'
    })
}

export function getChatRecords(liveId) {
    return axios({
        url: `lives/${liveId}/chat_records`,
        method: 'get'
    })
}

export function getIoServer () {
    return axios({
        url: `lives/io_server`,
        method: 'get'
    })
}

export function reportTimelines(parameter) {
    return axios({
        url: `lives/report_timelines`,
        method: 'post',
        data: parameter
    })
}

export function getLivePlaybacks(params) {
    return axios({
        url: `lives/playbacks`,
        method: 'get',
        params: params
    })
}

export function unsubscribe(params) {
    return axios({
        url: `subscribe`,
        method: 'delete',
        params: params
    })
}
export function subscribe(params) {
    return axios({
        url: `subscribe`,
        method: 'post',
        data: params
    })
}

export function isSubscribed(params) {
    return axios({
        url: `subscribe`,
        method: 'get',
        params: params
    })
}

export function isSubscribedOfficialAccount() {
    return axios({
        url: `subscribe/official_account`,
        method: 'get'
    })
}

export function getIntercessions(params) {
    return axios({
        url: `intercessions`,
        method: 'get',
        params: params
    })
}

export function storeIntercession(data) {
    return axios({
        url: `intercessions`,
        method: 'post',
        data: data
    })
}

