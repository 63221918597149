<template>
    <playback :channel-id="channel_id" @playback="handlePlayback"></playback>
</template>

<script>
import Playback from '@/view/live/widgets/Playback'
import wechatMixin from '@/utils/mixin'

export default {
    name: 'PlaybackIndex',
    mixins: [wechatMixin],
    components: { Playback },
    methods: {
        handlePlayback (playback) {
            this.$router.push({
                path: `/playback/${playback.id}`
            })
        }
    },
    data () {
        return {
            channel_id: null
        }
    },
    created() {
        this.registerWechatShare({
            title: '直播回放',
            description: '【诗119:105】你的话是我脚前的灯，是我路上的光。',
            thumb: 'https://file4.mogody.com/images/20200426/教会.jpg'
        })
        this.channel_id = this.$route.query.channel_id
    }
}
</script>
