<template>
    <div>
        <van-search v-model="keyword" @search="handleSearch" @clear="handleClear" placeholder="请输入标题搜索"/>
        <van-list
            :offset="50"
            v-model="listLoading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="加载,点击重新加载"
        >
            <van-cell
                @click="handlePlayback(playback)"
                v-for="playback in neededPlaybacks"
                :key="playback.id"
                class="playback-item"
            >
                <template #title>
                    <van-image
                        fit="cover"
                        width="40"
                        height="40"
                        :src="playback.shared"
                    />
                </template>
                <template #default>
                    <p>{{ playback.title }}</p>
                    <div class="van-cell__label">{{ playback.media.author }}</div>
                </template>
            </van-cell>
        </van-list>
    </div>
</template>

<script>
import { getLivePlaybacks } from '@/api/live'
export default {
    name: 'Playback',
    props: {
        channelId: {
            type: Number,
            require: false
        }
    },
    data () {
        return {
            listLoading: false,
            listFinished: false,
            error: false,
            keyword: null,
            playbacks: {
                meta: {
                    current_page: 0,
                    last_page: 0
                },
                data: []
            }
        }
    },
    computed: {
        neededPlaybacks () {
            return this.playbacks.data.filter(i => i.media)
        }
    },
    methods: {
        handleSearch () {
            this.refresh()
        },
        handleClear () {
            this.keyword = null
            this.refresh()
        },
        refresh () {
            this.playbacks.data = []
            this.playbacks.meta.current_page = 0
            this.listFinished = false
            this.listLoading = true
            this.onLoad();
        },
        handlePlayback (playback) {
            this.$router.push(`/video/${playback.media.id}`)
        },
        onLoad () {
            getLivePlaybacks({
                page: this.playbacks.meta.current_page + 1,
                title: this.keyword,
                channel_id: this.channelId
            }).then(res => {
                res.data.unshift(...this.playbacks.data)
                this.playbacks = res
                this.listLoading = false
                // 数据全部加载完成
                if (res.meta.current_page === res.meta.last_page) {
                    this.listFinished = true
                }
            }).catch(() => {
                this.error = true
            })
        }
    }
}
</script>

<style lang="less">
    .playback-item {
        .van-cell__title {
            flex: inherit;
            display: flex;
        }
        .van-cell__value {
            flex: inherit;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 10px;

            p {
                color: #323233;
                font-size: 14px;
                line-height: 22px;
                margin: 0px;
            }
        }
    }
</style>

